import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ILogger } from '../../observability/logger';
import { LOGGER_FACTORY, LoggerFactory } from '../../observability/provider';

export interface pendingTransactionsResponse {
  guarantorId: number;
  pendingTransactions: boolean;
  waitTime: number;
  amount: number;
}

@Injectable({
  providedIn: 'root',
})
export class PendingTransactionsService {
  response: pendingTransactionsResponse | null = null;

  public get complete(): boolean {
    return this._complete;
  }

  public set complete(value: boolean) {
    this.logger.debug('Property Changed', {
      name: 'complete',
      old: this._complete,
      new: value,
    });

    this._complete = value;
  }

  private _complete: boolean = false;

  responseSubject = new BehaviorSubject<pendingTransactionsResponse | null>(
    this.response
  );
  response$ = this.responseSubject.asObservable();

  endPointBase: string = '/PendingTransactions';

  private logger: ILogger;

  constructor(
    private http: HttpClient,
    @Inject(LOGGER_FACTORY) loggerFactory: LoggerFactory
  ) {
    this.logger = loggerFactory('PendingTransactionsService');
  }

  fetchPendingTransactions() {
    this.http
      .post<pendingTransactionsResponse>(
        environment.apiUrl + this.endPointBase,
        {},
        {
          observe: 'response',
        }
      )
      .subscribe({
        next: (res: HttpResponse<pendingTransactionsResponse>) => {
          if (res.body) {
            this.response = res.body;
            this.responseSubject.next(res.body);
            this.complete = true;
          }
        },
      });
  }

  getPendingTransactions() {
    return this.response; // Return data stored in the service
  }
}
